import React from 'react';
import Picture from './Picture';
import Meta from './Meta';
import Loading from './Loading';
import { Link } from 'gatsby';

export default function AlbumCover(props){
    const meta = props.meta
    return(
    <div className="content-element album-cover">
        {!props.URL ? <Loading /> :
        <Link to={props.URL} className="album-cover-picture">
        {!props.thumb ? <Loading /> :
        <Picture src={props.thumb} type="album-cover-picture"></Picture>}
        </Link>}
        
        {!props.URL ? <Loading /> :
        <h4><Link to={props.URL}>{props.title ? props.title : <Loading />}</Link></h4>
        }
        <Meta meta={meta} />
        <Link to={props.URL} className="see-more">Zobacz więcej...</Link>

        
    </div>
    );
}