import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import AlbumCover from '../components/AlbumCover';
import Loading from '../components/Loading';
import Layout from '../components/Layout';


export default function PhotosPage(){

    var data = useStaticQuery(graphql`
    query AlbumCoversQuery {
      markdownRemark(fileAbsolutePath: {regex: "/albumCovers/"}) {
        frontmatter {
          albumCovers {
            title
            URL
            meta {
              person
              time
              location
            }
            photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
      
    `);

    data = data.markdownRemark.frontmatter.albumCovers

    return(
      
    <Layout title="Zdjęcia">
    {!data ? <Loading /> :
        data.map((el, i)=><AlbumCover key={i} title={el.title} thumb={el.photo.childImageSharp.fluid} meta={el.meta} URL={`/photos/${el.URL}`} />)
    }
    </Layout>
    );
}